<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <!-- Content -->
    <v-card>
      <div class="filters">
        <h2 class="text-center">
          Observaciones<help
            class="mx-2"
            :helpId="65"
            :xSmall="true"
            style="vertical-align: baseline"
          />
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >Crear
              </v-btn>
            </template>
            <span>Crear Observación</span>
          </v-tooltip>
        </h2>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 50px"
                dark
                class="px-1 py-1"
                v-if="filterActivated"
                @click.prevent="
                  filterActivated = false
                  cleanFilters()
                  getObservations()
                "
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Eliminar filtro</span>
          </v-tooltip>
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getObservations()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @showPositionProfile="handleProfileRedirect($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Observación</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Cargo</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >

        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 40%"
                  >Evaluación Aplicada</label
                >
                <multiselect
                  style="width: 60%"
                  track-by="id"
                  label="observation"
                  placeholder="Seleccionar"
                  v-model="selectedAppliedEvaluation"
                  :options="appliedEvaluations"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 40%"
                  >Estatus</label
                >
                <multiselect
                  style="width: 60%"
                  track-by="value"
                  label="text"
                  placeholder="Seleccionar"
                  v-model="selectedStatus"
                  :options="status"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn small
              style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'
  export default {
    name: 'observations',
    components: {
      SimpleList,
      Help,
    },
    data() {
      return {
        orgChartIndex: 1,
        config: {},
        loading: true,
        isLoading: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Estatus', value: 'status' },
          { text: 'Evaluación Aplicada', value: 'applied_evaluation_id' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '120px' },
        ],
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Requerimientos',
            disabled: true,
          },
        ],
        flow: '',
        item: {},
        items: [],
        name: '',
        status: [
          { value: 0, text: 'Rechazado' },
          { value: 1, text: 'Aprobado' },
          { value: 2, text: 'En proceso' },
        ],
        selectedStatus: {},
        appliedEvaluations: [],
        selectedAppliedEvaluation: {},
        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        active: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true || '8 caracteres como mínimo'),
          max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) || 'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getAppliedEvaluations()
      this.getObservations()
    },

    watch: {
      company() {
        this.getObservations()
        this.getAppliedEvaluations()
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getObservations()
      },

      async getAppliedEvaluations() {
        this.$axios.get('applied-evaluations').then((response) => {
          this.appliedEvaluations = this.appliedEvaluations.concat(response.data.data)
        })
      },

      async getObservations() {
        this.loading = true
        // let queryParams = ''
        // let orderString = ''
        // if (this.orderBy.order && this.orderBy.by) {
        //   orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        // }
        // if (this.filterActivated) {
        //   let searchString = ''
        //   let countryString = ''
        //   let roleString = ''
        //   let activeString = ''
        //   if (this.filters.search !== '') {
        //     searchString = '&search=' + this.filters.search
        //   }
        //   if (this.filters.countryId && this.filters.countryId.id) {
        //     countryString = '&country_id=' + this.filters.countryId.id
        //   }
        //   if (this.filters.role && this.filters.role.name) {
        //     roleString = '&role=' + this.filters.role.name
        //   }
        //   if (this.filters.active && this.filters.active.id) {
        //     activeString = '&active=' + this.filters.active.id
        //   }
        //   queryParams = searchString + countryString + roleString + activeString
        // }
        this.$axios.get('observations').then((response) => {
          this.items = response.data.data
          if (this.items && this.items.length < 1) {
            this.loadingText = 'No hay registros'
          }
          this.totalItems = response.data.total
          this.itemsPerPage = response.data.per_page
          if (response.data.from) {
            this.page = response.data.current_page
            this.pages = response.data.last_page
          } else {
            this.page = 1
            this.pages = 1
          }
          this.loading = false
        })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.selectedStatus = {}
        this.selectedAppliedEvaluation = {}
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.selectedStatus = this.item.selectedStatus
        this.selectedAppliedEvaluation = this.item.selectedAppliedEvaluation
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      async createItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
        const formData = new FormData()
        formData.append('name', this.name)
        formData.append('status', this.selectedStatus.value)
        formData.append(
          'applied_evaluation_id',
          this.selectedAppliedEvaluation.assigned_evaluation_id
        )
        this.$axios.post('observations', formData, this.config).then(() => {
          this.page = 1
          this.getObservations()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        this.businessAgent = this.businessAgent ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('company_id', this.company.id)
        if (this.positionId && this.positionId.id) {
          formData.append('position_id', this.positionId.id)
        }
        if (this.strataId && this.strataId.id) {
          formData.append('strata_id', this.strataId.id)
        }
        if (this.functionalAreaId && this.functionalAreaId.id) {
          formData.append('functional_area_id', this.functionalAreaId.id)
        }
        formData.append('name', this.name)
        // formData.append('purpose', this.purpose)
        formData.append('salary', this.salary)
        formData.append('status', this.status)
        formData.append('business_agent', this.businessAgent)
        formData.append('active', this.active)
        formData.append('mission', this.mission)
        formData.append('vacancies', this.vacancies)
        formData.append('frozen_vacancies', this.frozenVacancies)
        formData.append('_method', 'patch')
        if (this.item.users && this.item.users.length > 0) {
          this.item.users.forEach((user) => {
            formData.append('users[]', user.id)
          })
        }
        this.$axios.post('positions/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getObservations()
          this.$modal.hide('crudModal')
        })
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async deleteItem(item) {
        this.$axios.delete('observations/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getObservations()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getObservations()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
